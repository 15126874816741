import React from 'react';
import logo from './logo.svg';
import './App.scss';


function App() {
  return (
    <div className="App">
      <header className="App-header">

        <img src={require('../src/static/images/brain.jpeg')} />

        <h1>
          The underbelly of Web3. A shadow vague, formless, but eternal.
        </h1>
      </header>
    </div>
  );
}

export default App;
